import React from 'react';
import Menu from './Menu'
import MenuMobile from './MenuMobile'

class App extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { idCommerce, table} = this.props
    console.log("RECIBO PARAMS", idCommerce, table)
    if(!idCommerce || table){
      return (
        <MenuMobile idCommerce={idCommerce} table={table}/>
        );
    }else{
      return (
        <Menu idCommerce={idCommerce}/>
      )
    }
      

  }
}

export default App;
