import React from 'react';
import './App.css'

const URL_IOS=process.env.REACT_APP_IOS_URL
const URL_ANDROID=process.env.REACT_APP_ANDROID_URL
class MenuMobile extends React.PureComponent {
  constructor(props) {
    super(props)
    this.urlStore = null
    if(navigator.userAgent.toLowerCase().match('android')){
      this.urlStore = URL_ANDROID
    }else{
      if(navigator.userAgent.toLowerCase().match('iphone')){
        this.urlStore = URL_IOS
      }
    }
    
  }
  render() {
    const { idCommerce, table} = this.props
      let hrefBeequickApp = idCommerce&&table?`beequick://idCommerce=${idCommerce}&table=${table}`:`beequick://`
      return (
        <div className="mainDiv" >
          <div className="paddingDiv">
          <img src="logo.png" width={133}/>
          </div>
          <div className="paddingDiv">
            <a className="buttonDJ" href={`beequick://`} target="_blank">
             <img src={"images/declaracionIcon.png"} style={{ height: 20 }} /> Llenar declaración jurada
            </a>
          </div>
          <div className="paddingDiv">
          <a className="buttonSecondary" href={hrefBeequickApp}>
            Ya tengo Beequick en mi dispositivo
          </a>
          </div>
          <div className="paddingDiv">
          <a className="buttonPrimary" href={this.urlStore}>
            Descargar Beequick
          </a>
          </div>
        </div>
      );
      

  }
}

export default MenuMobile;
