/**
 * Clase para hacer ajax requests
 * Devuelve una promesa con un json.
 *
 * let api = new APIConnector({timeout: 5000})
 * api.post('/api/user', {name: 'john doe', age:40})
 *    .then(response => {
 *    	if(response.ok()){
 *				console.log(response.body);
 *			} else {
 *				console.log(response.status, response.statusText, response.body)
 *      }
 *    })
 */

import Logger from "./Logger";
// real nasty old approaches js function
function Response(status, statusText, body = null) {
  if (status === 502 || status === 503 || status === 504) {
    statusText = "Error en la conexion con el servidor";
  }
  return {
    status,
    statusText,
    body,
    ok: () => {
      return status === 200 || status === 202 || status === 204 ? true : false;
    },
    isSessionExpired: () => {
      return status === 401 ? true : false;
    }
  };
}

const logger = new Logger("API-Connector");

const Methods = {
  HEAD: "HEAD",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
};

// Variable privada
let _defaultHeaders = {
  // "Access-Control-Request-Headers":"Content-Type, Accept, Authorization, App",
  // "Access-Control-Request-Method":"GET,POST, DELETE",
  "Content-Type": "application/json"//,
  // Accept: "application/json"
};

export default class APIConnector {
  constructor(options = {}) {
    // vars
    const { timeout = 0, debug = 1 } = options;
    if (timeout) this._timeout = timeout;
    this._debug = debug;
  }

  /*
	 * Setter/getter for default headers
	 */

  static set defaultHeaders(value) {
    _defaultHeaders = value;
  }
  static get defaultHeaders() {
    return _defaultHeaders;
  }

  /*
	 * getters
	 */

  static get Methods() {
    return Methods;
  }

  static get s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  static get generateUUID() {
    return (
      APIConnector.s4 +
      APIConnector.s4 +
      "-" +
      APIConnector.s4 +
      "-" +
      APIConnector.s4 +
      "-" +
      APIConnector.s4 +
      "-" +
      APIConnector.s4 +
      APIConnector.s4 +
      APIConnector.s4
    );
  }

  /*
	 * All supported methods below
	 */

  head(uri, args = {}) {
    return this._request(uri, { ...args, method: Methods.HEAD });
  }

  get(uri, args = {}) {
    return this._request(uri, { ...args, method: Methods.GET });
  }

  post(uri, args = {}) {
    return this._request(uri, { ...args, method: Methods.POST });
  }

  put(uri, args = {}) {
    return this._request(uri, { ...args, method: Methods.PUT });
  }

  patch(uri, args = {}) {
    return this._request(uri, { ...args, method: Methods.PATCH });
  }

  delete(uri, args = {}) {
    return this._request(uri, { ...args, method: Methods.DELETE });
  }

  /*
	 * Log funtions
	 */

  logRequest(uri, options) {
    if (!this._debug || this._debug <= 1) return;
    let logs = [];
    // method log
    logs.push(`Request ${options.method}: "${uri}"`);
    // header log
    let headers = Object.assign({}, options.headers);
    if (headers.Authorization) {
      headers.Authorization = "Bearer [removed]";
    }
    logs.push("Headers: " + JSON.stringify(headers));
    // body log
    if (options.body) {
      let logBody = "Body: " + JSON.stringify(options.body).substr(0, 80);
      if (options.body.length > 80) logBody += "...";
      logs.push(logBody);
    }
    // implode all array elements into a string and log it
    logger.info(logs.join(", "));
  }

  logResponse(method, uri, time) {
    if (!this._debug) return;
    logger.info(
      `Response ${method}: ${uri} completed, took: ${+new Date() - time}ms`
    );
  }

  /*
	 * Read stream as text and parse into json
	 */
  readResponse(response) {
    return new Promise(function(resolve, reject) {
      response
        .text()
        .then(text => {
          try {
            let json = {};
            if (text && text.length) {
              json = JSON.parse(text);
            }
            resolve(Response(response.status, response.statusText, json));
          } catch (e) {
            reject(Response(response.status, response.statusText, text));
          }
        })
        .catch(error => {

          // nasty unknown error here...
          logger.error("Unknown error trying to connect to server", error);
          reject(
            Response(
              400,
              "Error en la aplicación web. No es posible acceder a Internet."
            )
          );
        });
    });
  }

  /**
   * Internal method that make (and handle) the request
   *
   * @param string uri
   * @param object args
   * @return Promise
   */
  _request(uri, args = {}) {
    let time = new Date();
    let { method, headers = {}, body } = args;

    // uri validation
    if (!uri || uri instanceof String) {
      return logger.error(`No valid uri given for method ${method}`) && this;
    }

    // populate options with default data + user data
    let options = {};
    options.method = method;
    options.headers = { ..._defaultHeaders, ...headers };
    if (body) options.body = body;

    // create a log for the request
    // this.logRequest(uri, options);
    
    return new Promise((resolve, reject) => {
      let timeoutReached = false;
      let requestDone = false;

      // timeout feature...
      if (this._timeout) {
        setTimeout(() => {
          if (requestDone) return;
          timeoutReached = true;
          logger.info(
            `request ${method}: ${uri} timeout after ${+new Date() - time}ms`
          );
          reject(Response(500, "Timeout en la conexion"));
        }, this._timeout);
      }

      // manage the request
      fetch(uri, options)
        .then(response => {
          requestDone = true;
          if (timeoutReached) return;
          this.logResponse(method, uri, time);
          this.readResponse(response).then(json => {
            if (response.ok) {
              resolve(json);
            } else {
              console.log("REJECT READ RESPONSE NOT OK")
              reject(json);
            }
          });
        })
        .catch(error => {
          requestDone = true;
          if (timeoutReached) return;
          logger.error(
            `request ${method}: ${uri} raised error: "${error}", took ${+new Date() -
              time}ms`
          );
          // let response = Response(502, "", {});
          reject(error);
        });
    });
  }
}
