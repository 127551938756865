import React from 'react'
import Util from "../common/Util"

class MenuSuggested extends React.PureComponent{
  constructor(props){
    super(props)
  }

  renderItems(items, menuStyles={}){
    return items.map((m)=>{
      return (
        <div className="fh5co-v-half to-animate-2" >
          <div className="fh5co-v-col-2 fh5co-bg-img"
            style={{backgroundImage: `url(${m.mnuImg})`}}>
          </div>
          <div className="fh5co-v-col-2 fh5co-text fh5co-special-1 arrow-left" 
              style={{backgroundColor:menuStyles.backgroundColor, borderRightColor:menuStyles.backgroundColor}}>
            <div style={{borderRightColor:menuStyles.backgroundColor||"#faebcd", borderRightWidth:15, borderRightStyle:"solid", zIndex:1000
                      , borderTopColor:"transparent", borderTopWidth:15, borderTopStyle:"solid"
                      , borderBottomColor:"transparent", borderBottomWidth:15, borderBottomStyle:"solid"
                        ,width:0, height:0, position:"absolute", borderBottomColor:"transparent", top:50, left:-15 }}></div>
            <h2 style={{color:menuStyles.titleColor}}>{m.mnuName}</h2>
            <span className="pricing" style={{color:menuStyles.priceColor}}>{m.mnuHasStock?Util.formatCurrency(m.mnuPrice):"Sin Stock"}</span>
            <p style={{color:menuStyles.detailColor}}>{m.mnuDescription}</p>
          </div>
        </div>
      )
    })
  }

  render(){
    const {items, suggestOptions} = this.props
    if(!items||items.length==0){
      return null;
    }
    return (
      <div id="fh5co-featured" data-section="features" style={{background:suggestOptions.backgroundColor}}>
          <div className="container">
            <div className="row text-center fh5co-heading row-padded" >
              <div className="col-md-8 col-md-offset-2">
                <h2 className="heading to-animate">{suggestOptions.title}</h2>
              </div>
            </div>
            <div className="row">
              <div className="fh5co-grid" style={{justifyContent:"center"}}>
                {this.renderItems(items, suggestOptions.menu)}
              </div>
            </div>

          </div>
        </div>
    )
  }
}

MenuSuggested.defaultProps = {
  suggestOptions:{}
}

export default MenuSuggested