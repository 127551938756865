class Util {

	static formatCurrency(value, name){
		if(!value){
			return "$0,00"
		}
		let aux = value.toString()
		if(aux.endsWith(".")){
			aux=aux.substring(0,aux.length-1)+','
		}
		aux=aux.split('.')
		let integ = aux[0].toString().replace('.',',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
		let decim = aux[1]?"," + aux[1].substring(0,2):",00"
		decim = decim.length==3?decim:decim+"0"
		return "$" + integ + decim
	}
}

export default Util