import React from 'react'

class MenuItem extends React.PureComponent{
  constructor(props){
    super(props)
  }

  render(){
    return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style={this.props.style}
    width="64.000000pt" height="53.000000pt" viewBox="0 0 64.000000 53.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,53.000000) scale(0.100000,-0.100000)"
    fill={this.props.color} stroke="none">
    <path d="M272 500 c-24 -5 -57 -16 -73 -24 -24 -12 -35 -13 -49 -4 -22 14 -52
    -6 -48 -31 1 -9 -9 -32 -24 -52 -36 -46 -58 -100 -58 -140 0 -42 21 -110 35
    -116 7 -2 61 30 120 72 60 41 134 89 166 106 32 17 76 47 98 66 40 34 41 35
    26 64 -9 16 -26 36 -38 44 -30 19 -101 26 -155 15z m127 -33 c13 -6 29 -20 35
    -30 9 -15 5 -23 -22 -47 -18 -15 -57 -40 -85 -55 -29 -15 -96 -59 -151 -98
    -107 -76 -116 -76 -116 -4 0 54 42 137 90 178 68 57 192 86 249 56z m-249 -17
    c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
    <path d="M141 365 c-46 -50 -61 -81 -61 -126 0 -42 22 -32 27 11 4 38 19 64
    70 121 23 25 30 39 20 39 -8 0 -33 -20 -56 -45z"/>
    <path d="M444 245 c-16 -7 -42 -27 -58 -45 l-27 -33 -16 22 c-19 28 -57 43
    -89 35 -20 -5 -24 -12 -24 -44 0 -32 6 -44 34 -67 l35 -28 -122 0 c-71 0 -124
    -4 -127 -10 -3 -5 10 -11 29 -13 19 -2 41 -12 50 -23 16 -20 22 -20 200 -10
    101 6 188 8 192 5 4 -2 13 5 19 16 8 15 21 20 52 20 30 0 39 3 31 11 -11 11
    -164 16 -182 5 -6 -4 -11 -2 -11 3 0 6 4 11 10 11 5 0 25 14 45 31 32 28 36
    37 33 72 -4 48 -28 62 -74 42z m51 -26 c15 -23 -7 -60 -58 -94 -71 -48 -82
    -43 -22 10 54 48 41 66 -14 19 -37 -31 -39 -25 -8 17 34 47 88 72 102 48z
    m-170 -49 c16 -16 25 -33 22 -37 -4 -4 -14 3 -23 15 -8 12 -19 22 -25 22 -16
    0 -10 -23 13 -47 25 -27 13 -30 -18 -5 -22 19 -44 54 -44 71 0 22 47 10 75
    -19z m35 -84 c0 -15 -21 -26 -48 -26 -13 0 -11 5 8 20 30 23 40 25 40 6z
    m-200 -26 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
    10 -10z m40 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
    -4 10 -10z m30 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
    10 -4 10 -10z m255 -1 c-57 -11 -127 -11 -120 1 3 6 41 9 83 9 68 -1 72 -2 37
    -10z"/>
    </g>
    </svg>
    )
  }
}

export default MenuItem