import APIConnector from "./APIConnector"
const SERVICE_API = process.env.REACT_APP_API_BASE// "https://us-central1-beequick-dev.cloudfunctions.net/api"
class Service {
  
  static getMenu(restaurantId) {
    
    return new Promise(async (resolve, reject) => {
      try{
        // let token = ""//await AuthService.getToken()
        let endpoint = `${SERVICE_API}/restaurants/menu?idCommerce=${restaurantId}`
        console.log("ENDPOINT", endpoint)
        let response = await new APIConnector(3000).get(endpoint);
        if(response.status==200){
          resolve(response.body)
        }else{
          reject(response)
        }
      }catch(e){
        reject(e)
      }
    });
  }

}
export default Service