import React from 'react';
// import logo from './logo.svg';
// import './App.css';
import MenuIcon from "./components/MenuIcon"
import MenuItem from "./components/MenuItem"
import MenuSuggested from "./components/MenuSuggested"
import Service from "./provider/Service"
import Loadable from "react-loading-overlay";

class Menu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { menu: null, loading: true, categories: null }
    this.renderItem=this.renderItem.bind(this)
  }

  componentWillMount() {

    Service.getMenu(this.props.idCommerce).then((menu) => {
      // console.log("MENU", menu)
      let menuName = menu.resName||"Beequick"
      document.title = `${menuName} - Menu`
      // document.getElementById('logo').src=menu.resLogo
      // document.getElementById('logo1').src=menu.resLogo
      
      let categories = Object.keys(menu.menu).sort((k1, k2) => { return menu.menu[k1].order - menu.menu[k2].order })
      this.setState({ menu: menu, loading: false, categories: categories })
    })
  }

  renderItem(menuList) {
    const m = menuList.sort((m1,m2)=>{return m1.mnuName.localeCompare(m2.mnuName)})
    return m.map((menu, i) => {
      return (
        <MenuItem key={`menu${i}`} item={menu} menuStyles={this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.menu}/>
      )
    })
  }

  render() {
    const { categories, menu, loading } = this.state
    return (
      <Loadable
        spinner
        active={loading}
        text="Loading..."
        style={{ height: "100%" }}
      >
        <div style={{backgroundColor:this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.bodyBackgroundColor}}>
          <div id="fh5co-container">

            <div id="fh5co-home"
              className="js-fullheight"
              data-section="home">
              <div className="flexslider">
                <div className="fh5co-overlay"></div>
                <div className="fh5co-text">
                  <div className="container">
                    {/* <div className="paddingDiv">
                      <a className="buttonDJ" href={"https://form.jotform.com/202724099272659"}  target="_blank">
                      <img src={"images/declaracionIcon.png"} style={{ height: 20 }} /> Llenar declaración jurada
                      </a>
                    </div> */}
                    <div className="row">
                      <h1 className="to-animate"><img src={menu && menu.resLogo} style={{ height: 200 }} /></h1>
                      <h2 className="to-animate">Menú <span>by</span> <a href="https://beequick.app" target="_blank">Beequick</a></h2>

                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <ul className="fh5co-social" style={{ marginTop: 10 }}>
                          {menu&&menu.resFacebook?<li className="to-animate-2 col-xs-12 col-sm-4 col-md-4"><a href={`https://www.facebook.com/${menu.resFacebook}`} target="_blank" className="white"><i className="icon-facebook"></i><span style={{ marginLeft: 5 }}>/{menu.resFacebook}</span></a></li>:null}
                          {menu&&menu.resInstagram?<li className="to-animate-2 col-xs-12 col-sm-4 col-md-4"><a href={`https://www.instagram.com/${menu.resInstagram}`} target="_blank" className="white"><i className="icon-instagram"></i><span style={{ marginLeft: 5 }}>@{menu.resInstagram}</span></a></li>:null}
                          {menu&&menu.resTwitter?<li className="to-animate-2 col-xs-12 col-sm-4 col-md-4"><a href={`https://www.twitter.com/${menu.resTwitter}`} target="_blank" className="white"><i className="icon-twitter"></i><span style={{ marginLeft: 5 }}>@{menu.resTwitter}</span></a></li>:null}
                        </ul>
                      </div>
                    </div>
                    <div className="icon-scroll"></div>
                  </div>
                </div>
                <ul className="slides">
                  <li style={{ backgroundImage: "url(images/slide_1.jpg)" }} data-stellar-background-ratio="0.5"></li>
                  <li style={{ backgroundImage: "url(images/slide_2.jpg)" }} data-stellar-background-ratio="0.5"></li>
                  <li style={{ backgroundImage: "url(images/slide_3.jpg)" }} data-stellar-background-ratio="0.5"></li>
                </ul>
              </div>
            </div>


            <div className="js-sticky" >
              <div className="fh5co-main-nav" style={{background:this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.headerBackgroundColor||"#fff"}}>
                <div className="container-fluid">
                  <div className="fh5co-logo">
                    <a href="index.html"><img src={this.state.menu && this.state.menu.resLogo} style={{ height: 80 }} /></a>
                  </div>
                </div>
              </div>
            </div>

            <MenuSuggested items={this.state.menu && this.state.menu.suggests} 
                           suggestOptions={this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.suggest}/>


            <div id="fh5co-menus" data-section="menu">
              <div className="container">
               
                <div className="row text-center fh5co-heading row-padded" >
                  
                  <div className="col-md-8 col-md-offset-2">
                    <div>
                    <MenuIcon style={{position:"absolute", marginTop: -50, marginLeft: -32}} color={this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.menu && this.state.menu.resMenuOptions.menu.categoryColor}/>
                    <h2 className="heading to-animate" style={{padddingTop:0, color:this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.menu && this.state.menu.resMenuOptions.menu.categoryColor}}>Menú</h2>
                    </div>
                  </div>
                </div>
                <div className="row row-padded">
                  {categories && categories.map((category, i) => {
                    let cat = this.state.menu.menu[category]
                    return (
                      <div className="col-md-6" key={`cat${i}`} >
                        <div className="fh5co-food-menu to-animate-2">
                          {cat.icon&&<img src={cat.icon} width={64} height={64} style={{ position: "absolute", objectFit: "contain", color:this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.menu && this.state.menu.resMenuOptions.menu.categoryColor }} />}
                          <h2 className="fh5co-dishes" style={{color:this.state.menu && this.state.menu.resMenuOptions && this.state.menu.resMenuOptions.menu && this.state.menu.resMenuOptions.menu.categoryColor}}>{category}</h2>
                          <ul>
                            {this.renderItem(cat.menu)}
                          </ul>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="row">
                  <div className="col-md-4 col-md-offset-4 text-center to-animate-2">
                    <p><a href="/"
                      className="btn btn-primary btn-outline">Pedí con Beequick</a></p>
                  </div>
                </div>
              </div>
            </div>




          </div>

          <div id="fh5co-footer">

            <div className="container">
              <div className="row row-padded">
                <div className="col-md-12 text-center">
                  <p className="to-animate">&copy; Beequick 2020. </p>

                  <p><a href="https://beequick.app" target="_blank">Beequick.app</a></p>

                  <p className="text-center to-animate"><a href="#" className="js-gotop">Ir arriba</a></p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <ul className="fh5co-social">
                    <li className="to-animate-2"><a href="https://www.facebook.com/BeeQuick-107206674427334/" target="_blank"><i className="icon-facebook"></i><span style={{ marginLeft: 5 }}>/Beequick</span></a></li>
                    <li className="to-animate-2"><a href="https://instagram.com/beequickapp?r=nametag" target="_blank"><i className="icon-instagram"></i><span style={{ marginLeft: 5 }}>@beequickapp</span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>



        </div>
      </Loadable>
    );
  }
}

export default Menu;
