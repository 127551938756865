import React from 'react'
import Util from "../common/Util"

class MenuItem extends React.PureComponent{
  constructor(props){
    super(props)
  }

  render(){
    const {item, menuStyles} = this.props
    if(!item){
      return (
        <div></div>
      )
    }
    return (
      <li>
        <div className="fh5co-food-desc">
          <figure>
            <img src={item.mnuImg} className="img-responsive" alt="Beequick" />
          </figure>
          <div>
            <h3 style={{color:menuStyles.titleColor}}>{item.mnuName}</h3>
            <p style={{color:menuStyles.detailColor}}>{item.mnuDescription}</p>
          </div>
        </div>
        <div className="fh5co-food-pricing" style={{color:menuStyles.priceColor}}>
          {item.mnuHasStock?Util.formatCurrency(item.mnuPrice):"Sin Stock"}
         </div>
      </li>
    )
  }
  
}

MenuItem.defaultProps = {
  menuStyles:{}
}
export default MenuItem