import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import registerServiceWorker from "./registerServiceWorker";
const params = window.location.search.split("&")
const idCommerce = params[0].substring("?idCommerce=".length)
const table = params.length>1&&params[1].startsWith("table")&&params[1].substring("table=".length)
console.log("PARAMS", params, idCommerce, table)
ReactDOM.render(
  
    <App idCommerce={idCommerce} table={table} key="app"/>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// registerServiceWorker();

